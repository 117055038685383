<template>
  <v-card class="card-shadow border" color="white">
    <v-row class="justify-end pa-4 pb-0">
      <v-col cols="auto">
        <v-dialog v-model="dialogForGroup" persistent max-width="650px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Select Date</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-row align="center" class="pa-8 pt-0" style="gap: 20px">
                      <v-text-field v-model="dateRangeForCsvText" label="Date range" prepend-icon="mdi-calendar"
                                    @click:prepend="dialogForCsv = !dialogForCsv" @click="dialogForCsv = !dialogForCsv" readonly />
                      <v-btn color="secondary" @click.prevent="datesForCsv = [];">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-divider vertical />
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-title v-if="errorMessage" class="error-sec-btn mb-2">
              {{ errorMessage }}
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn color="error" text @click="dialogForGroup = false">
                Close
              </v-btn>
              <v-btn color="success" @click.prevent="downloadCsv()">
                Download csv
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="py-2" color="primary" outlined v-bind="attrs" v-on="on" @click.prevent="uploadDialog = true">
              <v-icon>mdi-paperclip</v-icon>
              upload
            </v-btn>
          </template>
          <span>Upload network files CSV</span>
        </v-tooltip>
      </v-col>
      <v-col cols="auto">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="py-2" color="dark" outlined v-bind="attrs" v-on="on" :loading="downloadBtnLoading"
                   :disabled="downloadBtnLoading" @click="handleCsvDateRange()">
              <v-icon>mdi-download</v-icon>
              Download Csv
            </v-btn>
          </template>
          <span>Download network files CSV</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row align="center" class="pa-8 pt-0" style="gap: 20px">
      <v-text-field v-model="dateRangeText" label="Date range" prepend-icon="mdi-calendar"
                    @click:prepend="dialog = !dialog" @click="dialog = !dialog" readonly />
      <v-btn color="primary" @click.prevent="UserList(search)">
        Search
      </v-btn>
      <v-btn color="secondary" @click.prevent="dates = []; UserList()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-divider vertical />
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" />
      <v-btn color="primary" @click.prevent="currentPage = 1; UserList(search)">
        Search
      </v-btn>
    </v-row>
    <v-data-table :headers="headers" :items="tableData" hide-default-footer :loading="loading" :loading-text="message"
                  :items-per-page="currentRows" class="network-datatable">
      <template v-slot:item.id="{ item }">
        <v-title text>
          {{ item.id ? item.id : '-' }}
        </v-title>
      </template>
      <template v-slot:item.transaction_id="{ item }">
        <v-title text>
          {{ item.transaction_id ? item.transaction_id : '-' }}
        </v-title>
      </template>
      <template v-slot:item.status="{ item }">
        <v-title text>
          {{ item.status ? item.status : '-' }}
        </v-title>
      </template>
      <template v-slot:item.account_id="{ item }">
        <v-title text>
          {{ item.account_id ? item.account_id : '-' }}
        </v-title>
      </template>
      <template v-slot:item.user_email="{ item }">
        <v-title text>
          {{ item.user_email ? item.user_email : '-' }}
        </v-title>
      </template>

      <template v-slot:item.pan_entry_mode="{ item }">
        <v-title text>
          {{ item.pan_entry_mode ?item.pan_entry_mode :'-' }}
        </v-title>
      </template>
      <template v-slot:item.pin_entry_mode="{ item }">
        <v-title text>
          {{ item.pin_entry_mode ? item.pin_entry_mode : '-' }}
        </v-title>
      </template>
      <template v-slot:item.program="{ item }">
        <v-title text>
          {{ item.program ? item.program :'-' }}
        </v-title>
      </template>
      <template v-slot:item.pan="{ item }">
        <v-title text>
          {{ item.pan ? item.pan : '-' }}
        </v-title>
      </template>
      <template v-slot:item.amount="{ item }">
        <v-title text>
          {{ item.amount ? item.amount : '-' }}
        </v-title>
      </template>
      <template v-slot:item.currency="{ item }">
        <v-title text>
          {{ item.currency ? item.currency : '-' }}
        </v-title>
      </template>
      <template v-slot:item.reason_code="{ item }">
        <v-title text>
          {{ item.reason_code ? item.reason_code : '-' }}
        </v-title>
      </template>
      <template v-slot:item.approval_number="{ item }">
        <v-title text>
          {{ item.approval_number !== null ? item.approval_number : '-' }}
        </v-title>
      </template>
      <template v-slot:item.surcharge_ind="{ item }">
        <v-title text>
          {{ item.surcharge_ind !== null ? item.surcharge_ind : '-' }}
        </v-title>
      </template>
      <template v-slot:item.purchase_date="{ item }">
        <v-title text>
          {{ item.purchase_date ? formatDate(item.purchase_date) : '-' }}
        </v-title>
      </template>
      <template v-slot:item.central_processing_date="{ item }">
        <v-title text>
          {{ item.central_processing_date ? formatDate(item.central_processing_date) : '-' }}
        </v-title>
      </template>
    
      <template v-slot:item.settlement_date="{ item }">
        <v-title text>
          {{ item.settlement_date ? formatDate(item.settlement_date) : '-' }}
        </v-title>
      </template>

      <template v-slot:item.created_at="{ item }">
        <v-title text>
          {{ item.created_at ? formatDate(item.created_at) : '-' }}
        </v-title>
      </template>

      <template v-slot:item.nrid="{ item }">
        <v-title text>
          {{ item.nrid !== null ? item.nrid : '-' }}
        </v-title>
      </template>
      <template v-slot:item.int_fee="{ item }">
        <v-title text>
          {{ item.int_fee !== null ? item.int_fee : '-' }}
        </v-title>
      </template>
      <template v-slot:item.int_fee_ind="{ item }">
        <v-title text>
          {{ item.int_fee_ind !== null ? item.int_fee_ind : '-' }}
        </v-title>
      </template>
      <template v-slot:item.intl_fee_ind="{ item }">
        <v-title text>
          {{ item.intl_fee_ind !== null ? item.intl_fee_ind : '-' }}
        </v-title>
      </template>
      <template v-slot:item.mid="{ item }">
        <v-title text>
          {{ item.mid !== null ? item.mid : '-' }}
        </v-title>
      </template>
      <template v-slot:item.merchant="{ item }">
        <v-title text>
          {{ item.merchant !== null ? item.merchant : '-' }}
        </v-title>
      </template>
      <template v-slot:item.merchant_country="{ item }">
        <v-title text>
          {{ item.merchant_country !== null ? item.merchant_country : '-' }}
        </v-title>
      </template>
      <template v-slot:item.type="{ item }">
        <v-title text>
          {{ item.type !== null ? item.type : '-' }}
        </v-title>
      </template>
      <template v-slot:item.original_type="{ item }">
        <v-title text>
          {{ item.original_type !== null ? item.original_type : '-' }}
        </v-title>
      </template>
      <template v-slot:item.batch_number="{ item }">
        <v-title text>
          {{ item.batch_number !== null ? item.batch_number : '-' }}
        </v-title>
      </template>
      <template v-slot:item.vrol_financial_id="{ item }">
        <v-title text>
          {{ item.vrol_financial_id !== null ? item.vrol_financial_id : '-' }}
        </v-title>
      </template>
      <template v-slot:item.vrol_case_number="{ item }">
        <v-title text>
          {{ item.vrol_case_number !== null ? item.vrol_case_number : '-' }}
        </v-title>
      </template>
      <template v-slot:item.vrol_bundle_case_number="{ item }">
        <v-title text>
          {{ item.vrol_bundle_case_number !== null ? item.vrol_bundle_case_number : '-' }}
        </v-title>
      </template>
      <template v-slot:item.client_case_number="{ item }">
        <v-title text>
          {{ item.client_case_number !== null ? item.client_case_number : '-' }}
        </v-title>
      </template>
      <template v-slot:item.dispute_condition="{ item }">
        <v-title text>
          {{ item.dispute_condition !== null ? item.dispute_condition : '-' }}
        </v-title>
      </template>
      <template v-slot:item.dispute_status="{ item }">
        <v-title text>
          {{ item.dispute_status !== null ? item.dispute_status : '-' }}
        </v-title>
      </template>
      <template v-slot:item.merchant_state="{ item }">
        <v-title text>
          {{ item.merchant_state !== null ? item.merchant_state : '-' }}
        </v-title>
      </template>
      <template v-slot:item.isa_indicator="{ item }">
        <v-title text>
          {{ item.isa_indicator !== null ? item.isa_indicator : '-' }}
        </v-title>
      </template>
    </v-data-table>
    <v-row align="center" justify="end">
      <v-col cols="auto">
        <v-select style="width: 60px;" :items="rowsPerPage" @change="handleChangeRows" v-model="currentRows" />
      </v-col>
      <v-col cols="auto" style="min-width: 400px;">
        <v-pagination @input="pageChange" color="black" v-model="currentPage" :length="noOfPages"
                      :total-visible="7" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-date-picker v-model="dates" range :max="today" />
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="dialog = false; dates = [];">
              Clear
            </v-btn>
            <v-btn color="primary" text @click="dialog = false;">
              Done
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogForCsv" persistent max-width="290">
        <v-card>
          <v-date-picker v-model="datesForCsv" range :max="today" />
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="dialogForCsv = false; datesForCsv = [];">
              Clear
            </v-btn>
            <v-btn color="primary" text @click="dialogForCsv = false;">
              Done
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="uploadDialog" max-width="290">
      <v-card>
        <v-card class="pa-4">
          <div class="upload-container" @click="openFileInput" @dragover.prevent @dragenter.prevent @dragleave.prevent
               @drop="handleFileDrop">
            <v-icon size="64">
              mdi-file-upload
            </v-icon>
            <p class="upload-text">
              {{ fileMessage }}
            </p>
          </div>
          <input type="file" ref="fileInput" @change="handleFileChange" style="display: none" accept=".csv">
        </v-card>

        <v-card-actions>
          <v-spacer />
          <v-btn color="error darken-1" text
                 @click="uploadDialog = false; selectedFile = null; fileMessage = 'Drag & Drop your CSV file here'">
            Close
          </v-btn>
          <v-btn color="green darken-1" text @click="SendCsvHandler">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="2000">
      File Uploaded Successfully
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>

export default {
  data() {
    return {
      currentPage: 1,
      noOfPages: 1,

      rowsPerPage: [10, 15, 30, 50],
      currentRows: 10,
      dialogForGroup: '',
      errorMessage: '',

      tableData: [],
      data: '',
      message: 'Loading... Please wait',
      loading: true,
      search: '',
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Txn Id', value: 'transaction_id' },
        { text: 'Status', value: 'status' },
        { text: 'Account Id', value: 'account_id' },
        { text: 'User Email', value: 'user_email' },
        { text: 'PAN Entry Mode', value: 'pan_entry_mode' },
        { text: 'PIN Entry Mode', value: 'pin_entry_mode' },
        { text: 'Program', value: 'program' },
        { text: 'Purchase Date', value: 'purchase_date' },
        { text: 'PAN', value: 'pan' },
        { text: 'Amount', value: 'amount' },
        { text: 'Currency', value: 'currency' },
        { text: 'Reason Code', value: 'reason_code' },
        { text: 'Approval Number', value: 'approval_number' },
        { text: 'NrId', value: 'nrid' },
        { text: 'Int Fee', value: 'int_fee' },
        { text: 'Int Fee Ind', value: 'int_fee_ind' },
        { text: 'Switch Fee Amount', value: 'switch_fee_amount' },
        { text: 'SRE', value: 'sre' },
        { text: 'Funds Transfer SRE', value: 'funds_transfer_sre' },
        { text: 'RRN', value: 'rrn' },
        { text: 'FPI', value: 'fpi' },
        { text: 'Integrity Fee', value: 'integrity_fee' },
        { text: 'IRF Base Amt', value: 'irf_base_amt' },
        { text: 'Net Settled Amount', value: 'net_settled_amount' },
        { text: 'Subnetwork', value: 'subnetwork' },
        { text: 'Surcharge Ind', value: 'surcharge_ind' },
        { text: 'Intl Fee Ind', value: 'intl_fee_ind' },
        { text: 'MId', value: 'mid' },
        { text: 'Merchant', value: 'merchant' },
        { text: 'Merchant Country', value: 'merchant_country' },
        { text: 'Type', value: 'type' },
        { text: 'Original Type', value: 'original_type' },
        { text: 'Central Processing Date', value: 'central_processing_date' },
        { text: 'Settlement Date', value: 'settlement_date' },
        { text: 'Batch Number', value: 'batch_number' },
        { text: 'Vrol Financial Id', value: 'vrol_financial_id' },
        { text: 'Vrol Case Number', value: 'vrol_case_number' },
        { text: 'Vrol Bundle Case Number', value: 'vrol_bundle_case_number' },
        { text: 'Client Case Number', value: 'client_case_number' },
        { text: 'Dispute Condition', value: 'dispute_condition' },
        { text: 'Dispute Status', value: 'dispute_status' },
        { text: 'Merchant State', value: 'merchant_state' },
        { text: 'ISA Indicator', value: 'isa_indicator' },
        { text: 'File Type', value: 'file_type' },
        { text: 'File Name', value: 'file_name' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Updated At', value: 'updated_at' },

      ],
      downloadBtnLoading: false,

      abortController: new AbortController(),

      dialog: false,
      dialogForCsv: false,
      today: new Date().toISOString(),
      dates: [],
      datesForCsv: [],

      uploadDialog: false,
      snackbar: false,
      selectedFile: null,
      fileMessage: 'Drag & Drop your CSV file here'
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    dateRangeForCsvText() {
      return this.datesForCsv.join(' ~ ')
    },
  },
  props: {
    currentServer: Object,
  },
  methods: {
    checkNull(value) {
      if (value === null || value === 'null' || value === '' || value === undefined) {
        return ''
      } else return value
    },
    formatDate(inputDate) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const date = new Date(inputDate);
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      const formattedDate = `${month} ${day}, ${year}, ${hours % 12}:${minutes.toString().padStart(2, '0')} ${ampm}`;

      return formattedDate;
    },
    getDateQuery(dates) {
      // Dates array
      const datesArray = dates;

      // Convert the date strings to Date objects
      const date1 = new Date(datesArray[0]);
      const date2 = new Date(datesArray[1]);

      // Find the older and newer dates
      const oldDate = date1 < date2 ? date1 : date2;
      const newDate = date1 < date2 ? date2 : date1;

      // Format the dates as YYYY-MM-DD strings
      const fromDate = oldDate.toISOString().split('T')[0];
      const toDate = newDate.toISOString().split('T')[0];

      // Create the final string
      return `from=${fromDate}&to=${toDate}`;
    
    },
    handleCsvDateRange() {
      this.dialogForGroup = true;
    },
    async UserList(searchString) {
      try {

        this.abortController.abort(); // Cancel previous requests
        this.abortController = new AbortController(); // Create new AbortController
        const signal = this.abortController.signal;

        this.loading = true
        this.message = 'Loading... Please wait'
        const v_page = '?page=' + this.currentPage;
        const v_per_page = '&per_page=' + this.currentRows;
        const SearchString = searchString ? '&search_string=' + searchString : '';
        const QueryDate = this.dates.length > 1 ? ('&' + this.getDateQuery(this.dates)) : '';

        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = process.env.VUE_APP_API_BASE_URL
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}api/v2/network_files_mqs${v_page + v_per_page + SearchString + QueryDate}`, {
          method: 'GET',
          'Accept': 'application/json',
          headers: myHeaders,
          'Content-Type': 'application/json',
          'redirect': 'follow',
          signal
        })
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        // Handle successful login here
        // const TableData = data?.data?.map((item) => {
        //   return {
        //     id: item?.id,
        //     ...item.attributes
        //   }
        // })
        // this.data = JSON.stringify(TableData);
        const TableData = data?.data;
        this.tableData = TableData;
        this.noOfPages = Math.ceil(parseInt(data?.total_count) / this.currentRows)
        this.loading = false
        this.message = ''

      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.name === 'AbortError') {
          // Request was cancelled
          this.loading = true
          this.message = 'Loading... Please wait'
        } else if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.loading = false
          this.message = 'something went wrong'
        }
        this.loading = false
        this.message = 'something went wrong'
      }
    },
    pageChange() {
      this.UserList(this.search);
    },
    handleChangeRows() {
      this.currentPage = 1
      this.UserList(this.search);
    },

    // this area is for upload csv file 
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadFile(file);
      }
    },
    handleFileDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file) {
        this.uploadFile(file);
      }
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    uploadFile(file) {
      // Handle the file upload logic here
      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        // File is a CSV, you can proceed with the upload logic
        this.selectedFile = file;
        this.fileMessage = file.name;
        console.log('Uploading CSV file:', file.name);
        // Implement your upload logic here
      } else {
        // File is not a CSV
        alert('Please upload a CSV file');
      }
    },
    async SendCsvHandler() {
      // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
      const baseurl = process.env.VUE_APP_API_BASE_URL
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      try {
        const response = await fetch(baseurl + 'api/v2/network_files_mqs/test_upload', {
          method: 'POST',
          headers: myHeaders,
          body: formData,
          redirect: 'follow'
        });
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else if (response.status == '422') {
            throw new Error('Unprocessable Entity');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.text();
        console.log(data)
        this.fileMessage = 'Drag & Drop your CSV file here'
        this.uploadDialog = false
        this.snackbar = true
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          alert(error.message)
          this.uploadDialog = false
          this.fileMessage = 'Drag & Drop your CSV file here'
        }
      }
    },
    async downloadCsv() {
      this.downloadBtnLoading = true;
      // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
      const baseurl = process.env.VUE_APP_API_BASE_URL
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
      const search = this.search ? '&search_string=' + this.search : '';
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      try {
        const url = this.datesForCsv?.length ? `&${this.getDateQuery(this.datesForCsv)}`:''
        const response = await fetch(baseurl +`api/v2/network_files_mqs/download_csv?export_csv=true${url}` + search, requestOptions);
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          }
        }
        const data = await response.text();

        if(response.status == 200){
          const blob = new Blob([data], { type: 'text/csv' });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'data.csv'; // Specify the filename for the downloaded file
          link.target = '_blank'
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          this.downloadBtnLoading = false;
        }else {
          const responsedata = JSON.parse(data);
          this.errorMessage = responsedata.error;
          setTimeout(() => {
              this.errorMessage = '';
          }, 8000);
        }

      } catch (error) {
        this.downloadBtnLoading = false;
        this.dialogForDownload = false;
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.errorBar = true
          this.errorMessage = error.message
        }
      }
    },
  },
  mounted() {
    this.UserList();
  },
};
</script>
<style lang="scss">
.network-datatable {
  th {
    white-space: nowrap;
  }
}

.upload-container {
  border: 2px dashed #ccc;
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

.upload-text {
  margin-top: 10px;
}

input[type="file"] {
  display: none;
}
</style>
